<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-title>
      <span class="headline"><v-icon>mdi-label-percent</v-icon>法人別卸掛率更新</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="JANCODE" filled dense readonly v-model="inputModel.jancode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="タイトル" filled dense readonly v-model="inputModel.title"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" filled dense readonly v-model="inputModel.productName"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="上代金額"
                filled
                dense
                type="number"
                readonly
                v-model="inputModel.retailPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="仕入掛率"
                filled
                dense
                type="number"
                readonly
                v-model="inputModel.purchaseRate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="仕入価格"
                filled
                dense
                type="number"
                readonly
                v-model="inputModel.purchasePrice"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="selectedRows.length == 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetRate"
                >仕入掛率反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" :style="gridStyle">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 100%"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @cell-value-changed="onCellValueChanged"
                :getRowNodeId="(data) => data.customerCd"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon="mdi-label-percent"
      title="法人別卸掛率更新"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";
import { PercentColumn } from "../../models/ag-grid/columnTypes";

export default {
  name: "ProductCompanyMultiplicationRate",
  props: ["inputModel", "corporetionRateModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    DialogBar,
    ErrorGridDialog,
  },
  data() {
    return {
      gridOptions: {
        columnTypes: {
          dpPercentColumn: PercentColumn,
        },
      },
      gridStyle: { height: "90%" },
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      errorColmuns: [
        { headerName: "取引先名", field: "customerName" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rules: {
        required: Validation.required,
        isRate: Validation.isRate,
        maxLength: Validation.maxLength,
        maxRateLength: Validation.maxRateLength,
      },
      errorRows: [],
    };
  },
  mounted() {
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 450 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 450 + "px";
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      //this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    async onBtnSetRate() {
      try {
        this.loadingOn();
        var customerCds = [];
        this.selectedRows.forEach((row) => {
          customerCds.push(row.customerCd);
        });
        const response = await this.$store.dispatch("corporationRate/calc", {
          customerCds: customerCds,
          productCd: this.inputModel.productCd,
        });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        var rates = result.rates;
        this.selectedRows.forEach((row) => {
          rates.forEach((rate) => {
            if (rate.customerCd == row.customerCd && row.wholesaleRate != rate.wholesaleRate) {
              row.updatedRate = true;
              row.wholesaleRate = rate.wholesaleRate;
              row.unitPrice = Math.round(this.inputModel.retailPrice * (rate.wholesaleRate / 100));
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectionRows });
      } catch (error) {
        console.error("ProductCompanyMultiplicationRate::onBtnSetRate", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onCellValueChanged(params) {
      params.data.updatedRate = true;
      params.data.unitPrice = Math.round(this.inputModel.retailPrice * (params.data.wholesaleRate / 100));

      this.updateList.push(params.data);
      let rows = [];
      rows.push(params.node);
      this.gridApi.redrawRows({ rowNodes: rows });
    },
    async init(inputModel) {
      try {
        const response = await this.$store.dispatch("corporationRate/search", { productCd: inputModel.productCd });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        var rates = result.rates;
        rates.forEach((rate) => {
          rate.unitPrice = Math.round(inputModel.retailPrice * (rate.wholesaleRate / 100));
        });
        this.gridApi.setRowData(rates);
        this.columnApi.autoSizeColumns();
        this.gridApi.refreshCells();
      } catch (error) {
        console.error("ProductCompanyMultiplicationRate::init", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onUpdateClick() {
      try {
        this.loadingOn();
        var updateItems = [];
        var updateList = [];
        this.errorRows = [];
        let updateRows = [];
        var isValid = true;
        this.gridApi.forEachNode((record) => {
          if (record.data.updatedRate) {
            var error = this.validateRow(record.data);
            if (error != true) {
              this.errorRows.push({
                customerName: record.data.customerName,
                errorMessage: error,
              });
              isValid = false;
            } else {
              updateList.push({
                productCd: this.inputModel.productCd,
                customerCd: record.data.customerCd,
                customerName: record.data.customerName,
              });
              updateItems.push({
                productCd: this.inputModel.productCd,
                customerCd: record.data.customerCd,
                wholesaleRate: parseFloat(record.data.wholesaleRate),
                updateDate: record.data.updateDate,
              });
            }
          }
        });
        if (isValid) {
          const response = await this.$store.dispatch("corporationRate/update", { updateItems: updateItems });
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  var errorRecord = updateList.find((record) => record.customerCd == key);
                  this.errorRows.push({
                    customerCd: key,
                    customerName: errorRecord.customerName,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 登録に成功したデータ(エラーにないデータ)を抽出
                this.gridApi.forEachNode((row) => {
                  let isError = this.errorRows.some((r) => r.number === row.data.number);
                  if (!isError) {
                    updateRows.push(row);
                  }
                });
                // 更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`商品単価、卸掛率の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`商品単価、卸掛率を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("onEditSubmit");
                this.init(this.inputModel);
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.$refs.updateErrorGrid.open({ records: this.errorRows });
          this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("ProductCompanyMultiplicationRate::onUpdateClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 卸単価
      this.setValidMessage(this.rules.required(row.wholesaleRate), "卸掛率", messages);
      this.setValidMessage(this.rules.isRate(row.wholesaleRate), "卸掛率", messages);
      this.setValidMessage(this.rules.maxRateLength(3, 1)(row.wholesaleRate), "卸掛率", messages);

      // 商品単価
      this.setValidMessage(this.rules.required(row.unitPrice), "商品単価", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.unitPrice), "商品単価", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      //suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
        width: 50,
      },
      {
        headerName: "法人名",
        field: "corporationName",
        width: 160,
        pinned: "left",
      },
      {
        // headerName: "法人名",
        headerName: "取引先名",
        field: "customerName",
        width: 300,
        pinned: "left",
      },
      {
        headerName: "卸掛率",
        field: "wholesaleRate",
        type: "dpPercentColumn",
        filter: "agNumberColumnFilter",
        editable: true,
        cellStyle: (params) => {
          if (params.data.updatedRate) {
            return { backgroundColor: "yellow" };
          }
          return {};
        },
        width: 100,
      },
      {
        headerName: "商品単価",
        field: "unitPrice",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: NumberValueFormatter(),
        cellStyle: (params) => {
          if (params.data.updatedRate) {
            return { backgroundColor: "yellow" };
          }
          return {};
        },
        width: 100,
      },
    ];
  },
};
</script>
