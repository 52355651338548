<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title
          ><v-icon>mdi-image</v-icon>{{ isRegisteredMode ? "登録商品画像一覧" : "新商品画像登録" }}</v-app-bar-title
        >
        <v-spacer></v-spacer>
        <v-form ref="searchForm" lazy-validation>
          <search-conditions @search="onSearchImage">
            <v-textarea
              clearable
              class="mx-2"
              label="jancode"
              v-model="searchModel.jancode"
              :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
              dense
              rows="1"
            ></v-textarea>
            ※検索をするとツリー選択が利用できなくなります。
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onSearchImage">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="!isRegisteredMode && allowedAction(constants.updateActions)"
          :disabled="selectedCards.length != 1"
          icon="mdi-pencil-outline"
          @click="onBtnEdit"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <product-image-edit
            :fileName="selectedCards.length == 1 ? selectedCards[0].name : ``"
            @onDialogClose="onDialogClose"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></product-image-edit>
        </v-dialog>
        <tooltip-icon-button
          v-if="!isRegisteredMode && allowedAction(constants.deleteActions)"
          :disabled="selectedCards.length == 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" :disabled="selectedCards.length == 0" @click="onBtnExport"
          >ダウンロード</tooltip-icon-button
        >
      </v-app-bar>
    </v-row>
    <v-row>
      <v-col
        v-if="!isRegisteredMode && (isMakerUser || (isCafereoUser && allowedAction(constants.updateActions)))"
        cols="12"
      >
        <simple-file-drop style="height: 145px" :multiple="true" @input="onInputFile"></simple-file-drop>
      </v-col>
    </v-row>
    <v-row>
      <v-toolbar dense>
        <tooltip-icon-toggle-button
          icon="mdi-file-tree"
          v-if="isRegisteredMode && !this.isMakerUser"
          v-model="detailSelected"
          >ツリー表示</tooltip-icon-toggle-button
        >
        <v-spacer></v-spacer>
        <tooltip-icon-button icon="mdi-checkbox-marked" @click="onBtnAllCheck">すべてチェック</tooltip-icon-button>
        <tooltip-icon-button icon="mdi-checkbox-blank-outline" @click="onBtnAllUncheck"
          >すべてチェックを外す</tooltip-icon-button
        >
      </v-toolbar>
    </v-row>
    <v-row style="height: 100%-145px">
      <v-col
        v-if="detailSelected && isRegisteredMode && !this.isMakerUser"
        style="flex-basis: auto; height: 100%"
        cols="3 : 12"
      >
        <v-text-field v-model="treeSearch" label="" clear-icon="mdi-close-circle-outline"></v-text-field>
        <v-treeview
          selectable
          :items="treeItems"
          :open="treeOpen"
          @input="onclickTreeNode"
          v-model="categoryTree"
          :search="treeSearch"
          :filter="treefilter"
          dense
          style="color: rgba(0, 0, 0, 0.6); font-size: 16px"
          item-disabled="locked"
        ></v-treeview>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
      </v-col>
      <v-col
        style="height: 100%; flex-basis: auto"
        :cols="detailSelected && isRegisteredMode && !this.isMakerUser ? 9 : 12"
      >
        <v-row>
          <v-col cols="12" sm="3" v-for="displayModel in displayModels" :key="displayModel.id">
            <product-image-card
              ref="images"
              :inputModel="displayModel"
              :isImgClickCheck="false"
              :isClickSelected="false"
              @onCheck="onCheck"
            ></product-image-card>
          </v-col>
          <v-col cols="12" sm="3" v-if="displayModels.length == 0 && isRegisteredMode && !this.isMakerUser">
            仕入先を選択してください。
          </v-col>
          <v-col v-if="displayModels.length != 0" cols="12">
            <v-pagination v-model="pageNumber" :length="maxPage" @input="changePage"></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import FileUtils from "../../utils/FileUtils";
import { statuses as ApiStatus } from "../../libs/api-client";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ProductImageCard from "../../components/product/ProductImageCard.vue";
import SimpleFileDrop from "../../components/common/SimpleFileDrop.vue";
import ProductImageEdit from "../../components/product/ProductImageEdit.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";

export default {
  name: "ProductImageSearch",
  data() {
    return {
      // 権限グループ
      constants: {
        updateActions: ["C010302", "M010202"],
        deleteActions: ["C010303", "M010203"],
      },
      searchModel: {
        jancode: "",
        categoryId: "",
        supplierCd: "",
      },
      editDialog: { show: false, width: "800px" },
      editModel: {
        createNew: false,
      },
      treeItems: [
        // 階層イメージ
        // {
        //   id: 1,
        //   name: "すべてのカテゴリ",
        //   children: [
        //     {
        //       id: 2,
        //       name: "仕入先A",
        //       children: [
        //         { id: 3, name: "雑貨" },
        //         { id: 4, name: "食玩" },
        //         { id: 5, name: "プラモデル" },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       name: "仕入先B",
        //       children: [
        //         { id: 7, name: "雑貨" },
        //         { id: 8, name: "食玩" },
        //         { id: 9, name: "プラモデル" },
        //       ],
        //     },
        //   ],
        // },
      ],
      detailSelected: true,
      categoryTree: [1],
      treeOpen: [1],
      treeSearch: null,
      inputModels: [],
      selectedCards: [],
      displayModels: [],
      searchItems: [],
      checkNode: [],
      productImages: [],
      selectedPageSize: null,
      pageNumber: 1,
      maxPage: 1,
      errorColmuns: [
        { headerName: "対象項目", field: "errorId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        isNumber: Validation.isNumber,
      },
      errorRows: [],
    };
  },
  components: {
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ProductImageCard,
    SimpleFileDrop,
    ProductImageEdit,
    ErrorGridDialog,
  },
  watch: {
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.onPageSizeChanged();
    },
  },
  computed: {
    isRegisteredMode() {
      console.log(this.$route.name);
      return this.$route.name !== "ProductImageEntry";
    },
    treefilter() {
      return undefined;
    },
  },
  mounted() {
    this.onSearchClick();
  },
  methods: {
    async onclickTreeNode(node) {
      //
      try {
        this.loadingOn();
        this.inputModels = [];
        console.log(node);
        for (const elm of node) {
          this.searchModel.supplierCd = elm;
          this.searchModel.categoryId = null;
          this.searchModel.isNullCategory = false;
          this.searchModel.registeredMode = this.isRegisteredMode;
          if (this.isCafereoUser && !this.isRegisteredMode) {
            this.searchModel.newImage = true;
          }
          // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
          this.searchModel.jancodes = this.searchModel.jancode
            ? this.searchModel.jancode
                .replaceAll(/\n+/g, " ")
                .split(" ")
                .filter((n) => n)
            : null;
          const response = await this.$store.dispatch("productImage/search", { searchCondition: this.searchModel });
          console.debug("productImage/search:", response);
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }

          this.inputModels = this.inputModels.concat(response.data.contents.images);
        }
        this.displayModels = this.inputModels.slice(0, this.selectedPageSize);
        this.maxPage = Math.ceil(this.inputModels.length / this.selectedPageSize);
        this.onPageSizeChanged();
        // 検索したときのため保存
        this.checkNode = node;
      } catch (error) {
        console.error("ProductSearch::onApprovalSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onSearchImage() {
      //
      if (this.validate()) {
        try {
          this.loadingOn();
          this.inputModels = [];
          this.displayModels = [];
          this.selectedCards = [];
          if (this.searchModel.jancode) {
            this.treeItems = this.treeItems.map((element) => {
              element.locked = true;
              return element;
            });
            if (this.isRegisteredMode) {
              this.searchModel.isNullCategory = true;
            } else {
              this.searchModel.isNullCategory = false;
            }
            this.searchModel.registeredMode = this.isRegisteredMode;
            if (this.isCafereoUser && !this.isRegisteredMode) {
              this.searchModel.newImage = true;
            }
            // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
            this.searchModel.jancodes = this.searchModel.jancode
              ? this.searchModel.jancode
                  .replaceAll(/\n+/g, " ")
                  .split(" ")
                  .filter((n) => n)
              : null;
            const response = await this.$store.dispatch("productImage/search", { searchCondition: this.searchModel });
            console.debug("productImage/search:", response);
            if (ApiStatus.isSystemError(response.data?.header)) {
              return this.redirectError();
            }
            this.inputModels = response.data.contents.images;
            this.displayModels = this.inputModels.slice(0, this.selectedPageSize);
            this.maxPage = Math.ceil(this.inputModels.length / this.selectedPageSize);
            this.onPageSizeChanged();
          } else {
            this.onSearchClick();
          }
        } catch (error) {
          console.error("ProductSearch::onApprovalSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onCheck(event) {
      if (event.check) {
        this.selectedCards.push(event.inputModel);
        console.log("追加後::");
        console.log(this.selectedCards);
      } else {
        var index = 0;
        for (let selectedCard of this.selectedCards) {
          console.log(selectedCard);
          if (selectedCard.name == event.inputModel.name) {
            break;
          }
          index = index + 1;
        }
        this.selectedCards.splice(index, 1);
        console.log("削除後::");
        console.log(this.selectedCards);
      }
    },
    onBtnAllCheck() {
      this.$refs.images.forEach((card) => {
        card.onCheckOn();
        var index = 0;
        this.selectedCards.forEach((selectedCord) => {
          if (selectedCord.name == card.name) {
            index = index + 1;
          }
        });
        if (index == 0) {
          this.selectedCards.push(card.inputModel);
        }
      });
    },
    onBtnAllUncheck() {
      this.$refs.images?.forEach((card) => {
        card.onCheckOff();
        var index = 0;
        this.selectedCards.forEach((selectedCord) => {
          if (selectedCord.name == card.name) {
            index = index + 1;
          }
        });
        this.selectedCards.splice(index - 1, 1);
      });
    },
    async onBtnDelete() {
      try {
        let deleteList = [];

        // 選択一覧の中のトレーディング商品を取得する
        this.inputModels.forEach((inputModel) => {
          if (inputModel.check) {
            deleteList.push(inputModel);
          }
        });
        console.log(deleteList);

        let messageText = `選択された商品画像を削除します <small>(${deleteList.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "商品画像一覧", text: messageText });
        if (ok) {
          const result = await this.$store.dispatch("productImage/delete", { products: deleteList });

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    errorId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "商品画像削除", records: errorRows });
                this.$dialog.notify.error(`商品画像削除処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`商品画像が削除されました (${deleteList.length}件)`, { timeout: 2300 });
              }
              break;
            default:
              this.redirectError();
              break;
          }
          this.onSearchClick();
        }
      } catch (error) {
        console.error("ProductImageSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        if (this.isRegisteredMode && !this.isMakerUser) {
          console.log("linkID:", this.linkId);
          let response = null;
          // ツリーの作成
          response = await this.$store.dispatch("productImage/getProductImageTree", {});
          console.debug("productImage/getProductImageTree:", response);
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          let old = null;
          this.treeItems = [];
          // let treeItem = {
          //   id: 1,
          //   name: "すべてのカテゴリ",
          //   children: [],
          // };
          let supplierItems = {};
          response.data.contents.imageTree.forEach((elm) => {
            // 初回は初期化
            if (!old) {
              supplierItems = { id: elm.supplierCd, name: elm.supplierName, children: [] };
            }
            // キーが変わったときに仕入先ノードの処理
            if (old && elm.supplierCd != old.supplierCd) {
              // 2022/03/11のCafereo打ち合わせでツリーには商品マスタがない画像が不要になった。
              //categoryItems.push({ id: old.supplierCd + "-*", name: "その他" });
              //treeItem.children.push(supplierItems);
              this.treeItems = this.treeItems.concat(supplierItems);
              supplierItems = { id: elm.supplierCd, name: elm.supplierName, children: [] };
            }
            old = elm;
          });
          // 2022/03/11のCafereo打ち合わせでツリーには商品マスタがない画像が不要になった。
          // categoryItems.push({ id: old.supplierCd + "-*", name: "その他" });
          if (!this.isMakerUser) {
            this.treeItems.push(supplierItems);
          }
          //this.treeItems.push(treeItem);
          this.treeOpen = [1];

          this.onclickTreeNode(this.checkNode);
        } else {
          this.onclickTreeNode([this.$store.getters["security/loggedInUser"].groupCode]);
          this.onBtnAllUncheck();
        }
      } catch (error) {
        //return this.redirectError();
        console.error("onSearchClick:", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnEdit() {
      this.editModel = {
        createNew: false,
      };
      this.editDialog.show = true;
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    onEditSubmit() {
      this.editDialog.show = false;
      this.$dialog.notify.info(`商品画像を更新しました`, {
        timeout: 2300,
      });
      this.onSearchClick();
    },
    async onBtnExport() {
      try {
        this.loadingOn("画像ファイルを圧縮中です");
        var products = [];
        this.selectedCards.forEach((row) => {
          products.push({ logicalFileName: row.name });
        });

        const response = await this.$store.dispatch("productImage/download", { products: products });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        if (response.data?.header.resultCode == ApiStatus.consts.BUSINESS_ERROR) {
          this.$dialog.warning({
            title: "商品画像ダウンロード",
            text: response.data?.header.messages[0][0],
            actions: ["OK"],
          });
        } else {
          const exportUrl = response.data.contents.exportFilePath;
          FileUtils.downloadFile(exportUrl);
        }
      } catch (error) {
        console.error("ProductImageSearch::onBtnExport", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onInputFile(files) {
      try {
        this.errorRows = [];
        this.loadingOn();
        this.productImages = [];
        for (const file of files) {
          if (file.type.toLowerCase().indexOf("image/") !== 0) {
            this.$dialog.notify.error(`画像形式のみアップロード可能です(${file.name})`);
            return;
          }
          const [width, height] = await FileUtils.getImageSize(file);
          if (!(width >= 500 && width <= 1200 && height >= 500 && height <= 1200)) {
            this.$dialog.notify.error(
              `画像サイズは縦横500～1200Pixel以内にしてください(${file.name} / ${width}x${height})`
            );
            return;
          }
          if ("同じファイル名はダメ" == width) {
            //NOP
          }
          this.productImages.push({ name: file.name });
        }
        console.log("productImages:", this.productImages);
        const response = await this.$store.dispatch("productImage/entry", { productImages: this.productImages });
        console.log("productImage/entry", response.data);
        let messages = null;
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            // NOP
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
          case ApiStatus.consts.ALREADY_CHANGED:
            messages = response.data?.header.messages;
            break;
          default:
            this.redirectError();
            break;
        }
        // エラーメッセージ格納
        if (messages) {
          Object.keys(messages).forEach((key) => {
            this.errorRows.push({
              errorId: key,
              errorMessage: messages[key],
            });
          });
        }
        if (this.errorRows.length > 0) {
          this.$refs.bulkErrorGrid.open({ records: this.errorRows });
        } else {
          this.$refs.bulkErrorGrid.close();
        }
        if (response.data.contents.productImages.length > 0) {
          await this.$store.dispatch("filing/imageUpload", {
            files: files,
            productImages: response.data.contents.productImages,
          });
          this.$emit("onEditSubmit", this.updateModel);
          this.$dialog.notify.info(`商品画像を登録しました<small>(${files.length} 件)<small>`, {
            timeout: 2300,
          });
        }
        this.onSearchClick();
      } catch (error) {
        console.error("ProductEntry::onUpdateClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.maxPage = Math.ceil(this.inputModels.length / this.selectedPageSize);
      this.pageNumber = 1;
      this.displayModels = this.inputModels.slice(0, this.selectedPageSize);
    },
    changePage() {
      this.displayModels = this.inputModels.slice(
        this.selectedPageSize * (this.pageNumber - 1),
        this.selectedPageSize * this.pageNumber
      );
    },
    // ***** バリデーション関連 *****
    validate() {
      let isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    this.maxPage = Math.ceil(this.inputModels.length / this.selectedPageSize);
  },
};
</script>
